import React, { useState } from "react"
import style from "./tabs.mod.scss"

const Tabs = ({
  tabNames,
  // isSelected,
  children = []
}) => {
  const [currentTab, setTab] = useState(0)

  // TODO: Animate switiching between tabs
  // const variants = {
  //   hidden: {
  //     opacity: 0
  //   },
  //   tab0: {
  //     x: '4.75%',
  //     opacity: 1
  // },
  //   tab1: {
  //     x: '48.7%',
  //     opacity: 1
  //   },
  //   tab2: {
  //     x: '92%',
  //     opacity: 1Surface
  //   }
  // }

  return (
    <div className={style.tabs} data-current-tab={currentTab}>
      <ul className={style.tabs__list}>
        {tabNames.map((tabName, index) => {
          return (
            <li
              className={style.tabs__list_item}
              key={`tab-${tabName}`}
              data-is-current={currentTab === index}
            >
              <button
                onClick={() => setTab(index)}
                className={style.tabs__button}
              >
                {tabName}
              </button>
            </li>
          )
        })}
      </ul>
      <div className={style.tabs__progress}>
        {/* <motion.div className={style.tabs__indicator} variants={variants} initial={{ x: '4.75%' }} animate={isSelected === currentTab ? `tab${currentTab}` : "hidden"}  /> */}
      </div>
      <div className={style.tab__plans}>
        {children.filter((child, index) => currentTab === index)}
      </div>
    </div>
  )
}

export default Tabs
