import React, { useEffect, useState } from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import CardsContainer from "@global/cards/CardsContainer"
import Tabs from "@elements/Tabs"
import Block from "@global/layout/Block"
import { transformFavouriteNode } from "@helpers/favouritesHelper"
import { useAuth0 } from "@utils/auth"
import { HelmetDatoCms } from "gatsby-source-datocms"
import FavouriteHeartButton from "@global/cards/FavouriteHeartButton"
import { useFavourite } from "../context/FavouritesContext/FavouriteState"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Spinner from "@global/layout/Spinner"
import Thumb from "@global/cards/Thumb"

const AccountFavouritesPage = ({
  path,
  data,
  pageContext: { title, parent }
}) => {
  const {
    loading,
    user,
    favouriteWorkouts,
    favouriteRecipes,
    favouriteVlogs
  } = useAuth0()

  const [currentTab, setTab] = useState(0)

  const {
    workouts,
    recipes,
    vlogs,
    getWorkoutsData,
    getRecipeData,
    getVlogData,
    hasFavourites
  } = useFavourite()

  useEffect(() => {
    if (user) {
      console.time()
      parseWorkoutsIdIntoObject()
      parseRecipesIdIntoObject()
      parseVlogsIdIntoObject()
      console.timeEnd()
    }
  }, [favouriteWorkouts, favouriteRecipes, favouriteVlogs])

  const parseWorkoutsIdIntoObject = () => {
    const parseFavouritesWorkout = data.workouts.nodes.filter(x =>
      favouriteWorkouts.includes(x.originalId)
    )

    getWorkoutsData(parseFavouritesWorkout)
  }

  const parseRecipesIdIntoObject = () => {
    const parseFavouritesRecipe = data.recipes.nodes.filter(x =>
      favouriteRecipes.includes(x.originalId)
    )
    getRecipeData(parseFavouritesRecipe)
  }

  const parseVlogsIdIntoObject = () => {
    const parseFavouritesVlogs = data.vlogs.nodes.filter(x =>
      favouriteVlogs.includes(x.originalId)
    )
    getVlogData(parseFavouritesVlogs)
  }

  return (
    <Layout title={title} parent={parent}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <HelmetDatoCms
            defer={false}
            title={`${title}${data.seo.globalSeo.titleSuffix}`}
          />
          <ProtectedRoute>
            <Block padding="both">
              <Tabs
                onClick={index => setTab(index)}
                currentTab={currentTab}
                tabNames={["WORKOUTS", "RECIPES", "TIPS & TRICKS"]}
              >
                <FavouritesTab
                  nodes={workouts}
                  key={"fav-workouts"}
                  type="workouts"
                />
                <FavouritesTab
                  nodes={recipes}
                  key={"fav-recipes"}
                  type="recipes"
                />
                <FavouritesTab nodes={vlogs} key={"fav-vlogs"} type="vlogs" />
              </Tabs>
            </Block>
          </ProtectedRoute>
        </>
      )}
    </Layout>
  )
}

export default AccountFavouritesPage

const FavouritesTab = ({ nodes, type = "workouts" }) => {
  const {
    removeWorkoutData,
    removeVlogsData,
    removeRecipesData
  } = useFavourite()
  const {
    removeFavouriteWorkouts,
    removeFavouriteRecipes,
    removeFavouriteVlogs
  } = useAuth0()

  const handlerRemoveFavourites = id => {
    if (type === "workouts") {
      removeWorkoutData(id)
      removeFavouriteWorkouts(id)
    } else if (type === "recipes") {
      removeRecipesData(id)
      removeFavouriteRecipes(id)
    } else if (type === "vlogs") {
      removeVlogsData(id)
      removeFavouriteVlogs(id)
    }
  }

  return nodes.length > 0 ? (
    <CardsContainer modifier={"thumbs"}>
      {nodes.map(node => {
        return (
          <Thumb
            key={`fav-${node.originalId}`}
            overlay={
              <FavouriteHeartButton
                id={node.originalId}
                onRemoveFavourite={() =>
                  handlerRemoveFavourites(node.originalId)
                }
                prompt={true}
                large={true}
              />
            }
            {...transformFavouriteNode(node, type)}
          />
        )
      })}
    </CardsContainer>
  ) : (
    <NoResult />
  )
}

const NoResult = () => (
  <Block gutters={true} padding="top">
    <center>{"No items favourited"}</center>
  </Block>
)

export const query = graphql`
  query FavouritesQuery {
    workouts: allDatoCmsWorkout {
      nodes {
        ...WorkoutThumb
      }
    }

    recipes: allDatoCmsRecipe {
      nodes {
        ...RecipeThumb
      }
    }

    vlogs: allDatoCmsVlogPost {
      nodes {
        ...VlogThumb
      }
    }

    content: contentYaml(language: { eq: "en-NZ" }) {
      tabs {
        favouritesLabels {
          workouts
          recipes
          vlogs
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`
