import React from "react"
import style from "./thumb.mod.scss"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const Thumb = ({ image, title, link, subHeading }) => {
  return (
    <React.Fragment>
      <Link to={link.path} className={style.thumb__link}>
        {image && (
          <GatsbyImage
            image={image.gatsbyImageData}
            className={style.thumb__image}
            alt={title}
          />
        )}
        <div className={style.thumb__text}>
          <h4 className={style.thumb__heading}>{title}</h4>
          {subHeading && (
            <h4 className={style.thumb__subheading}>{subHeading}</h4>
          )}
        </div>
      </Link>
    </React.Fragment>
  )
}

export default Thumb

Thumb.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  }).isRequired
}

export const query = graphql`
  fragment WorkoutThumb on DatoCmsWorkout {
    title
    path
    id
    originalId
    content {
      ... on DatoCmsEquipment {
        items {
          name
          model {
            apiKey
          }
          id
          icon {
            url
          }
        }
      }
    }
    category {
      title
    }
    media {
      ... on DatoCmsWorkoutVideoNative {
        video {
          url
        }
        model {
          apiKey
        }
        chosenThumbnail {
          gatsbyImageData(imgixParams: { w: "350" })
        }
      }

      ... on DatoCmsWorkoutVideo {
        id
        model {
          apiKey
        }
        chosenThumbnail {
          gatsbyImageData(imgixParams: { w: "350" })
        }
      }
      ... on DatoCmsWorkoutAudio {
        id
        model {
          apiKey
        }
        banner {
          gatsbyImageData(imgixParams: { w: "350" })
        }
      }
    }
  }

  fragment RecipeThumb on DatoCmsRecipe {
    title
    path
    id
    originalId
    heroImage {
      gatsbyImageData(imgixParams: { w: "350" })
    }
  }

  fragment VlogThumb on DatoCmsVlogPost {
    title
    path
    id
    originalId
    chosenThumbnail {
      gatsbyImageData(imgixParams: { w: "350" })
    }
  }
`
